var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c("div", { staticClass: "expense-content-title" }, [
            _c("span", { staticClass: "left" }),
            _c("span", { staticClass: "expense-col" }, [_vm._v("采购内容")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("数量")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("单位")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("单价")]),
            _c("span", { staticClass: "expense-col" }, [_vm._v("总价")]),
            _c("span", { staticClass: "right" }),
          ]),
          _c(
            "draggable",
            {
              attrs: { handle: ".item-drag" },
              model: {
                value: _vm.form.purchaseList,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "purchaseList", $$v)
                },
                expression: "form.purchaseList",
              },
            },
            _vm._l(_vm.form.purchaseList, function (item, index) {
              return _c(
                "div",
                { key: index, staticClass: "expense-content-item" },
                [
                  _c("div", { staticClass: "left" }, [
                    _vm._v(_vm._s(index + 1)),
                  ]),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "purchaseList[" + index + "].content" },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: item.content,
                          callback: function ($$v) {
                            _vm.$set(item, "content", $$v)
                          },
                          expression: "item.content",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "purchaseList[" + index + "].quantity" },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: { placeholder: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handleQuantityChange($event, index)
                          },
                        },
                        model: {
                          value: item.quantity,
                          callback: function ($$v) {
                            _vm.$set(item, "quantity", $$v)
                          },
                          expression: "item.quantity",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "purchaseList[" + index + "].unit" },
                    },
                    [
                      _c("a-auto-complete", {
                        attrs: {
                          "data-source": _vm.unitOptions,
                          placeholder: "请输入",
                        },
                        model: {
                          value: item.unit,
                          callback: function ($$v) {
                            _vm.$set(item, "unit", $$v)
                          },
                          expression: "item.unit",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "purchaseList[" + index + "].price" },
                    },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "input-filter",
                            rawName: "v-input-filter:number",
                            arg: "number",
                          },
                        ],
                        attrs: { placeholder: "" },
                        on: {
                          change: function ($event) {
                            return _vm.handlePriceChange($event, index)
                          },
                        },
                        model: {
                          value: item.price,
                          callback: function ($$v) {
                            _vm.$set(item, "price", $$v)
                          },
                          expression: "item.price",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a-form-model-item",
                    {
                      staticClass: "expense-col",
                      attrs: { prop: "purchaseList[" + index + "].amount" },
                    },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: item.amount,
                          callback: function ($$v) {
                            _vm.$set(item, "amount", $$v)
                          },
                          expression: "item.amount",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "right" },
                    [
                      index > 0
                        ? _c("img", {
                            staticClass: "item-drag",
                            attrs: { src: _vm.dragUrl },
                          })
                        : _vm._e(),
                      _c("a-icon", {
                        staticClass: "item-add",
                        attrs: { type: "plus-circle" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.handleAddExpense(index)
                          },
                        },
                      }),
                      index > 0
                        ? _c("a-icon", {
                            staticClass: "item-delete",
                            attrs: { type: "delete" },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.handleDeleteExpense(index)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }