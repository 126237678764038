<template>
  <div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="expense-content-title">
        <span class="left"></span>
        <span class="expense-col">采购内容</span>
        <span class="expense-col">数量</span>
        <span class="expense-col">单位</span>
        <span class="expense-col">单价</span>
        <span class="expense-col">总价</span>
        <span class="right"></span>
      </div>
      <draggable v-model="form.purchaseList" handle=".item-drag">
        <div class="expense-content-item" v-for="(item, index) in form.purchaseList" :key="index">
          <div class="left">{{ index + 1 }}</div>
          <!-- 采购内容 -->
          <a-form-model-item :prop="`purchaseList[${index}].content`" class="expense-col">
            <a-input placeholder="" v-model="item.content" />
          </a-form-model-item>
          <!-- 数量 -->
          <a-form-model-item :prop="`purchaseList[${index}].quantity`" class="expense-col">
            <a-input
              placeholder=""
              v-model="item.quantity"
              @change="handleQuantityChange($event, index)"
              v-input-filter:number
            />
          </a-form-model-item>
          <a-form-model-item :prop="`purchaseList[${index}].unit`" class="expense-col">
            <a-auto-complete v-model="item.unit" :data-source="unitOptions" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item :prop="`purchaseList[${index}].price`" class="expense-col">
            <a-input
              placeholder=""
              v-model="item.price"
              @change="handlePriceChange($event, index)"
              v-input-filter:number
            />
          </a-form-model-item>
          <a-form-model-item :prop="`purchaseList[${index}].amount`" class="expense-col">
            <a-input placeholder="" v-model="item.amount" />
          </a-form-model-item>

          <div class="right">
            <img :src="dragUrl" class="item-drag" v-if="index > 0" />
            <a-icon type="plus-circle" class="item-add" @click.stop="handleAddExpense(index)" />
            <a-icon type="delete" class="item-delete" @click.stop="handleDeleteExpense(index)" v-if="index > 0" />
          </div>
        </div>
      </draggable>
    </a-form-model>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import { delPurchaseList } from '@/api/iot/purchaseList'
import { AutoComplete } from 'ant-design-vue'

export default {
  name: 'ExpenseList',
  props: {
    crules: {
      type: Object,
      required: true
    },
    cform: {
      type: Object,
      required: true
    },
    unitOptions: {
      type: Array,
      required: true
    }
  },
  components: { draggable, AAutoComplete: AutoComplete },
  data() {
    return {
      loading: false,
      rules: this.crules,
      form: this.cform,
      dragUrl: require('@/assets/images/drag.png'),
      purchaseList: {
        content: '',
        quantity: '',
        unit: '',
        price: '',
        amount: ''
      }
    }
  },
  filters: {},
  created() {
    console.log('created')
  },
  computed: {},
  watch: {
    cform(newVal, oldVal) {
      console.log('cform change', newVal)
      this.form = newVal
      this.initPurchaseList()
      this.toCalcAmount()
      this.addRule(0)
    }
  },
  mounted() {
    console.log('cform', this.form)
    this.initPurchaseList()
    this.toCalcAmount()
    this.addRule(0)
  },
  methods: {
    initPurchaseList() {
      if (this.cform.purchaseList.length === 0) {
        this.cform.purchaseList.push(this.purchaseList)
        this.$forceUpdate()
      }
    },
    addRule(index) {
      this.rules = this.rules || {}
      if (index >= 0) {
        this.rules[`purchaseList[${index}].content`] = [{ required: true, message: '项目不能为空', trigger: 'blur' }]
        this.rules[`purchaseList[${index}].quantity`] = [{ required: true, message: '数量不能为空', trigger: 'blur' }]
        this.rules[`purchaseList[${index}].unit`] = [{ required: true, message: '单位不能为空', trigger: 'blur' }]
        this.rules[`purchaseList[${index}].price`] = [{ required: true, message: '单价不能为空', trigger: 'blur' }]
      }
    },
    handleAddExpense() {
      // const index = this.form.purchaseList.length - 1
      this.form.purchaseList.push({
        content: '',
        quantity: '',
        unit: '',
        price: ''
      })
      // this.form.purchaseList.splice(index + 1, 0, this.purchaseList)
      this.addRule(this.form.purchaseList.length - 1)
    },
    handleDeleteExpense(index) {
      this.$confirm({
        title: '确认要删除所选中数据?',
        content: '',
        onOk: () => {
          if (index < this.form.purchaseList.length) {
            // 先删除校验规则
            const item = this.form.purchaseList[index]
            if (item.id) {
              delPurchaseList(item.id).then(response => {
                this.toDeletePurchaseDetail(item, index)
              })
            } else {
              this.toDeletePurchaseDetail(item, index)
            }
          }
        },
        onCancel: () => {}
      })
    },
    toDeletePurchaseDetail(item, index) {
      for (const fieldName in item) {
        const key = `purchaseList[${index}].${fieldName}`
        const rules = this.rules
        for (const r in rules) {
          if (r === key) {
            delete this.rules[key]
            break
          }
        }
      }
      this.form.purchaseList.splice(index, 1)
      this.toSum()
    },
    handleQuantityChange(e, index) {
      const quantity = Number(e.target.value).toFixed(2)
      const price = Number(this.form.purchaseList[index].price).toFixed(2)
      this.form.purchaseList[index].amount = (quantity * price).toFixed(2)
      this.toSum()
    },
    handlePriceChange(e, index) {
      const price = Number(e.target.value).toFixed(2)
      const quantity = Number(this.form.purchaseList[index].quantity).toFixed(2)
      this.form.purchaseList[index].amount = (quantity * price).toFixed(2)
      this.toSum()
    },
    toCalcAmount() {
      this.form.purchaseList.forEach(p => {
        p.amount = (Number(p.quantity) * Number(p.price)).toFixed(2)
      })
      this.$forceUpdate()
    },
    toSum() {
      let sum = 0
      this.form.purchaseList.forEach(p => {
        sum += Number(p.quantity) * Number(p.price)
      })
      this.$forceUpdate()
      this.$emit('setAmount', sum.toFixed(2))
    },
    getPurchaseList() {
      return this.form.purchaseList
    }
  }
}
</script>
<style lang="less" scoped>
.expense-content-title,
.expense-content-item {
  display: flex;
  .expense-col {
    width: 25% !important;
    margin: 0 3px;
    text-align: center;
  }
  > .left {
    flex: 0 0 35px;
    min-width: 35px;
    text-align: right;
    padding-right: 3px;
  }
  > .right {
    flex: 0 0 100px;
    min-width: 100px;
    display: flex;
  }
}

.expense-content-title {
  .expense-col {
    font-weight: bold;
    text-align: center;
  }
}

.expense-content-item {
  > .left {
    height: 40px;
    line-height: 40px;
  }
  > .right {
    .item-drag,
    .item-add,
    .item-delete {
      font-size: 25px;
      margin: 0 3px;
      height: 40px;
      line-height: 40px;
    }
    .item-add {
      line-height: 45px !important;
    }
  }
}
.item-delete {
  margin-top: 2px !important;
}
.item-drag:hover {
  cursor: pointer;
}
</style>
